@font-face {
  font-family: 'SFThonburi-Regular';
  src: local('SFThonburi'),
   url('./re-design/assets/fonts/SFThonburi-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'SFThonburi-Bold';
  src: local('SFThonburi'),
   url('./re-design/assets/fonts/SFThonburi-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'SFThonburi-Semibold';
  src: local('SFThonburi'),
   url('./re-design/assets/fonts/SFThonburi-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: local('SFThonburi'),
   url('./re-design/assets/fonts/Gilroy-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSansThai-Regular';
  src: local('SFThonburi'),
   url('./re-design/assets/fonts/IBMPlexSansThai-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSansThai-Medium';
  src: local('SFThonburi'),
   url('./re-design/assets/fonts/IBMPlexSansThai-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansThai-Regular';
  src: local('SFThonburi'),
   url('./re-design/assets/fonts/NotoSansThai-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansThai-Bold';
  src: local('SFThonburi'),
   url('./re-design/assets/fonts/NotoSansThai-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSansThai-SemiBold';
  src: local('SFThonburi'),
   url('./re-design/assets/fonts/IBMPlexSansThai-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSansThai-Regular-ABCD';
  src: local('SFThonburi'),
   url('./re-design/assets/fonts/IBMPlexSansThai-Regular.ttf') format('truetype');
}
  
:root {
  --main-theme-color: #fe6202;
  --main-theme-color-hover: #fe8512;
  --main-body-bg-color: #fefefe;
  --main-theme-text-color: #65676b;
  --main-theme-bg-footer-color: #f5f5f5;
  --main-theme-topic-footer-color: #c4c4c4;
}

body {
  overflow-x: hidden;
  background-color: var(--main-body-bg-color);
}