.contact-us-container {
  margin-top: 217px;
}

.contact-us-container h2 {
  color: var(--main-theme-text-color);
  margin-bottom: 15px;
}

.contact-us-container p {
  color: var(--main-theme-text-color);
  line-height: 1.25;
}

.contact-us-container .contact-us-container-header-en {
  color: rgb(0, 0, 72);
  font-family: IBMPlexSansThai-Regular-ABCD;
  font-size: 35px;
}

.contact-us-container .contact-us-container-header-th {
  color: rgb(0, 0, 72);
  font-family: IBMPlexSansThai-SemiBold;
  font-size: 35px;
}

.contact-us-container .contact-us-container-description-en {
  font-family: SFThonburi-Regular;
  font-size: 20px;
}

.contact-us-container .contact-us-container-description-th {
  font-family: IBMPlexSansThai-Regular;
  font-size: 20px;
}

.contact-us-container .contact-us-channel {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contact-us-container .Rectangle-18010 {
  position: relative;
  width: 100px;
  height: 100px;
  margin-right: 40px;
  padding: 17px;
  border-radius: 10px;
  box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #ececec;
  background-color: #fff;
  cursor: pointer;
  float: left;
  transition: 1s box-shadow;
  letter-spacing: 2px;
}

.contact-us-container .Rectangle-18010:hover {
  box-shadow: 0 5px 35px 0px rgba(0,0,0,.1);
}

.contact-us-container .Rectangle-18010:hover::before, .contact-us-container .Rectangle-18010:hover::after {
  position: absolute;
  z-index: -1;
  display: block;
  content: '';
  width: 100px;
  height: 100px;
  background: #FDA8CF;
  border-radius: 10px;
  animation: 1s clockwise infinite;
}

.contact-us-container .Rectangle-18010:hover:after {
  background: #F3CE5E;
  animation: 2s counterclockwise infinite;
}

@keyframes clockwise {
  0% {
    top: -5px;
    left: 0;
  }
  12% {
    top: -2px;
    left: 2px;
  }
  25% {
    top: 0;
    left: 5px;    
  }
  37% {
    top: 2px;
    left: 2px;
  }
  50% {
    top: 5px;
    left: 0;    
  }
  62% {
    top: 2px;
    left: -2px;
  }
  75% {
    top: 0;
    left: -5px;
  }
  87% {
    top: -2px;
    left: -2px;
  }
  100% {
    top: -5px;
    left: 0;    
  }
}

@keyframes counterclockwise {
  0% {
    top: -5px;
    right: 0;
  }
  12% {
    top: -2px;
    right: 2px;
  }
  25% {
    top: 0;
    right: 5px;    
  }
  37% {
    top: 2px;
    right: 2px;
  }
  50% {
    top: 5px;
    right: 0;    
  }
  62% {
    top: 2px;
    right: -2px;
  }
  75% {
    top: 0;
    right: -5px;
  }
  87% {
    top: -2px;
    right: -2px;
  }
  100% {
    top: -5px;
    right: 0;    
  }
}
