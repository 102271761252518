.main-container {
  position: relative;
}

.bg-ellipse-home {
  position: absolute;
  z-index: -1;
  top: -48px;
  right: 700px;
}

.bg-img-home {
  position: absolute;
  z-index: -2;
  right: -410px;
  top: -390px;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(10vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 0.8s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.container-header-divider {
  width: 0px;
  background-color: #e53639;
  border: 2px solid #e53639;
  /* border-top: 3px solid var(--main-theme-color-hover); */
  margin: auto;
  opacity: 1;
  transition: width 0.8s;

}

.container-header-divider.is-divider-visible {
  width: 55px;

}

::-webkit-scrollbar {
  /* width: 5px; */
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background-color: var(--main-theme-bg-footer-color);
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background-color: #e53639;
  border-radius: 100px;
}

hr:not([size]) {
  height: 0px;
}

.hide {
  display: none;
}

.t-a-c {
  text-align: center;
}

.t-a-r {
  text-align: right;
}

.pad-l-0 {
  padding-left: 0px;
}

.pad-l-10 {
  padding-left: 10px;
}

.pad-r-10 {
  padding-right: 10px;
}

.pad-r-15 {
  padding-right: 15px;
}

.mrg-t-10 {
  margin-top: 10px;
}

.mrg-t-12 {
  margin-top: 12px;
}

.mrg-t-15 {
  margin-top: 15px;
}

.mrg-t-18 {
  margin-top: 18px;
}

.mrg-t-30 {
  margin-top: 30px;
}

.mrg-t-35 {
  margin-top: 35px;
}

.mrg-t-40 {
  margin-top: 40px;
}

.mrg-t-45 {
  margin-top: 45px;
}

.mrg-t-50 {
  margin-top: 50px;
}

.mrg-t-60 {
  margin-top: 60px;
}

.mrg-t-65 {
  margin-top: 65px;
}

.mrg-t-75 {
  margin-top: 75px;
}

.mrg-t-80 {
  margin-top: 80px;
}

.mrg-t-85 {
  margin-top: 85px;
}

.mrg-t-90 {
  margin-top: 90px;
}

.mrg-t-95 {
  margin-top: 95px;
}

.mrg-t-100 {
  margin-top: 100px;
}

.mrg-b-4 {
  margin-bottom: 4px;
}

.mrg-b-10 {
  margin-bottom: 10px;
}

.mrg-b-15 {
  margin-bottom: 15px;
}

.mrg-b-25 {
  margin-bottom: 25px;
}

.mrg-b-30 {
  margin-bottom: 30px;
}

.mrg-b-40 {
  margin-bottom: 40px;
}

.mrg-l-28 {
  margin-left: 28px;
}


@media (min-width:320px) {

  /* smartphones, iPhone, portrait 480x320 phones */
  .bg-image {
    background-position: right -146px top -165px;
  }

  .business-container img {
    width: -webkit-fill-available;
  }

  .contact-us-channel {
    overflow-x: hidden;
  }


}

@media (min-width:481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width:641px) {

  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .bg-image {
    background-position: right -218px top -354px;
  }
}

@media (min-width:961px) {

  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .bg-image {
    background-position: right -460px top -325px;
  }
}

@media (min-width:1025px) {

  /* big landscape tablets, laptops, and desktops */
  /* .bg-image {
    background-position: right -400px top -325px;
  } */
}

@media (min-width:1281px) {

  /* hi-res laptops and desktops */
  .bg-image {
    background-position: right -305px top -325px;
  }
}

@media (max-width:961px) {
  .main-container {
    padding-top: 750px;
  }

  .home-container h1 {
    font-size: 22px !important;
    text-align: center !important;
  }

  .home-container p {
    font-size: 14px !important;
    text-align: center !important;
  }

  .features-container {
    margin-top: 80px !important;
  }

  .features-container h2 {
    font-size: 22px !important;
    text-align: center !important;
  }

  .features-container h3 {
    font-size: 22px !important;
    text-align: center !important;
    min-height: unset !important;
  }

  .features-container p {
    font-size: 14px !important;
    text-align: center !important;
  }

  .features-container .col-first {
    margin-top: 80px !important;
    float: unset !important;
    text-align: center !important;
  }

  .features-container .col-second {
    float: unset !important;
    padding-left: unset !important;
  }

  .features-container .col-first img {
    width: 50px !important;
    height: 50px !important;
  }

  .features-container .fetures-xl {
    margin-top: 10px !important;
  }

  .features-container .mrg-t-85 {
    margin-top: 40px !important;
  }

  .footer-container {
    margin-top: 80px !important;
  }

  .business-container {
    margin-top: 80px !important;
  }

  .business-container h1 {
    font-size: 20px !important;
    text-align: center !important;
  }

  .business-container h2 {
    font-size: 22px !important;
    text-align: center !important;
  }

  .business-container h3 {
    font-size: 22px !important;
    text-align: center !important;
    min-height: unset !important;
  }

  .business-container p {
    font-size: 14px !important;
    text-align: center !important;
  }

  .contact-us-container .Rectangle-18010:hover::before,
  .contact-us-container .Rectangle-18010:hover::after {
    display: none !important;
  }

  .contact-us-container .Rectangle-18010:hover:after {
    display: none !important;
  }

  .contact-us-container .Rectangle-18010 {
    margin: 10px !important;
  }

  .navbar-brand {
    margin-left: 20px;
  }

  .ft-resp-v-Rectangle-17213 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .ft-resp-v-col-get-started {
    text-align: center;
  }

  .contact-us-container {
    margin-top: 80px !important;
  }


}


@media (max-width:482px) {

  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
  .main-container {
    padding-top: 275px;
  }

  .home-container h1 {
    font-size: 22px !important;
    text-align: center !important;
  }

  .home-container p {
    font-size: 14px !important;
    text-align: center !important;
  }

  .features-container {
    margin-top: 80px !important;
  }

  .features-container h2 {
    font-size: 22px !important;
    text-align: center !important;
  }

  .features-container h3 {
    font-size: 22px !important;
    text-align: center !important;
    min-height: unset !important;
    margin-top: 10px;
  }

  .features-container p {
    font-size: 14px !important;
    text-align: center !important;
  }

  .features-container .col-first {
    float: unset !important;
    text-align: center !important;
  }

  .features-container .col-second {
    float: unset !important;
    padding-left: unset !important;
  }

  .features-container .col-first img {
    width: 50px !important;
    height: 50px !important;
  }

  .features-container .fetures-xl {
    margin-top: 10px !important;
  }

  .features-container .mrg-t-85 {
    margin-top: 40px !important;
  }

  .footer-container {
    margin-top: 80px !important;
  }

  .business-container {
    margin-top: 100px !important;
  }

  .business-container h2 {
    font-size: 22px !important;
    text-align: center !important;
  }

  .business-container h3 {
    font-size: 22px !important;
    text-align: center !important;
    min-height: unset !important;
  }

  .business-container p {
    font-size: 14px !important;
    text-align: center !important;
  }

  .contact-us-container .Rectangle-18010:hover::before,
  .contact-us-container .Rectangle-18010:hover::after {
    display: none !important;
  }

  .contact-us-container .Rectangle-18010:hover:after {
    display: none !important;
  }

  .ft-resp-v-Rectangle-17213 {
    white-space: nowrap;
    display: unset;
    flex-wrap: unset;
    justify-content: unset;
    overflow-y: auto;
    padding-bottom: 15px;
  }

  .ft-resp-v-Rectangle-17213::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .home-container .Rectangle-17213 {
    float: unset !important;
    box-shadow: unset !important;
    /* margin: auto !important;
    margin-bottom: 20px !important; */

    display: inline-block;
  }

  .contact-us-container .Rectangle-18010 {
    margin: 10px !important;
  }

  .navbar-brand {
    margin-left: 5px;
  }

  .ft-resp-v-col-get-started {
    text-align: center;
  }

  .contact-us-container {
    margin-top: 80px !important;
  }

}



@media (max-width:1023px) {
  .home-logo {
    display: none;
  }



  .ft-resp {
    display: none;
  }

  .ft-resp-v {
    display: block !important;
  }

  .footer-container .footer-container-description a {
    font-size: 14px !important;
  }

  .ft-resp-v .footer-container-top {
    padding-top: 40px;
  }

  .ft-resp-v .footer-cp {
    font-size: 12px !important;
  }

  .footer-container {
    padding-top: unset !important;
  }

  .ft-resp-v .l-a-f {
    line-height: 1.2;
  }

  .navbar-light .navbar-toggler {
    border-color: transparent;
  }

  .navbar-toggler:focus {
    box-shadow: unset !important;
  }

  .ft-resp-v h2 {
    font-size: 22px !important;
  }

  .ft-resp-v p {
    font-size: 14px !important;
  }

  .ft-resp-v .Rectangle-18010 {
    width: 65px !important;
    height: 65px !important;
    padding: 12px !important;
  }

  .ft-resp-v .Rectangle-18010 img {
    width: 40px !important;
    height: 40px !important;
  }

  .bg-image {
    background-size: 160%;
  }
}

.get-start-btn-en {
  background-color: rgb(0, 0, 72);
  border-color: rgb(0, 0, 72);
  box-shadow: none;
  width: 50%;
  border-radius: 5px;
  font-family: SFThonburi-Regular;
}

.get-start-btn-en:hover {
  background-color: rgb(0, 0, 72);
  border-color: rgb(0, 0, 72);
  box-shadow: none;
}

.get-start-btn-en:focus {
  background-color: rgb(0, 0, 72);
  border-color: rgb(0, 0, 72);
  box-shadow: none;
}

.get-start-btn-en:active:focus {
  box-shadow: none;
}

.get-start-btn-th {
  background-color: rgb(0, 0, 72);
  border-color: rgb(0, 0, 72);
  box-shadow: none;
  width: 50%;
  border-radius: 5px;
  font-family: IBMPlexSansThai-Regular;
}

.get-start-btn-th:hover {
  background-color: rgb(0, 0, 72);
  border-color: rgb(0, 0, 72);
  box-shadow: none;
}

.get-start-btn-th:focus {
  background-color: rgb(0, 0, 72);
  border-color: rgb(0, 0, 72);
  box-shadow: none;
}

.get-start-btn-th:active:focus {
  box-shadow: none;
}


@media (min-width:320px) {

  .bg-image {
    background-size: 0px;
  }

  /* smartphones, iPhone, portrait 480x320 phones */
  .main-container {
    padding-top: 35vw;
  }

  .features-container {
    margin-top: 100px !important;
  }

  .features-container .col-first {
    margin-top: 35px !important;
  }

  .ft-resp-v-Rectangle-17213 {
    padding-left: unset;
    padding-right: unset;
  }

  .home-container p {
    font-size: 12px !important;
    text-align: center !important;
  }

  /* .business-container p {
    font-size: 12px !important;
  } */

  .business-container .business-container-content-start {
    padding: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .business-container .business-container-content-end {
    padding: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .home-container .Rectangle-17213 {
    margin-right: 10px !important;
  }


  .business-container h1 {
    margin-bottom: 15px !important;
  }

  .ft-resp-v h2 {
    font-size: 21px !important;
  }

  .business-container-left {
    position: relative !important;
    /* left: -35px; */
  }

  .business-container-right {
    position: relative !important;
    /* left: 35px; */
  }

  .business-container .business-container-description-start-en {
    text-align: center !important;
    font-family: SFThonburi-Regular;
    padding: 0px 30px;
  }

  .business-container .business-container-description-start-th {
    text-align: center !important;
    font-family: IBMPlexSansThai-Regular;
    padding: 0px 30px;
  }

  .business-container .business-container-description-end-en {
    text-align: center !important;
    font-family: SFThonburi-Regular;
    padding: 0px 30px;
  }

  .business-container .business-container-description-end-th {
    text-align: center !important;
    font-family: IBMPlexSansThai-Regular;
    padding: 0px 30px;
  }

  .background-image {
    height: 123vw;
    width: 100%;
    object-fit: cover;
    object-position: bottom;
    position: absolute;
    top: -48vw;
  }

  .row-hidden-a {
    display: none;
  }

  nav .navbar-collapse {
    position: fixed;
    top: 56px;
    background: white;
    right: -100%;
    width: 100vw;
    height: 100vh;
    display: block;
    transition: right 0.008s ease;
  }

  nav .navbar-collapse.show {
    right: 0;
  }

  .navbar-hidden-row {
    color: rgb(0, 0, 72);
    display: flex;
  }

  .hide-cookie-mobile {
    display: unset;
  }

  .hide-cookie-web {

    margin: auto;
    text-align: center;
    display: none;

  }

  .hide-mail-contact-mb {
    display: unset;
  }

  .hide-mail-contact-w {
    display: none;
  }

  .bg-img-home {
    display: none;
  }
}

@media (min-width:481px) {

  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
  .main-container {
    padding-top: 45vw;
  }

  .business-container-left {
    position: relative !important;
    /* left: -55px; */
  }

  .business-container-right {
    position: relative !important;
    /* left: 55px; */
  }

  .business-container img {
    width: 75vw;
    height: 100%;

  }
}

@media (min-width:641px) {

  .business-container h1 {
    font-size: 30px !important;
    text-align: center !important;
  }

  .business-container p {
    font-size: 20px !important;
    text-align: center !important;
  }

  .background-image {
    height: 115vw;
    width: 100%;
    object-fit: cover;
    object-position: bottom;
    position: absolute;
    top: -38vw;
  }

  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .main-container {
    padding-top: 60vw;
  }

  .business-container img {
    width: 75vw;
    height: 100%;
  }

  .business-container-left {
    position: relative !important;
    /* left: -120px; */
  }

  .business-container-right {
    position: relative !important;
    /* left: 120px; */
  }

  /* .business-container .business-container-content-start {
    padding: 0px 0px 0px 60px !important;

  } */

  /* .business-container .business-container-content-end {
    padding: 0px 60px 0px 0px !important;

  } */


}


@media (min-width:961px) {

  .home-logo {
    display: unset;
  }

  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .main-container {
    padding-top: unset;
  }

  .background-image {
    display: none;
  }

  .home-container p {
    font-size: 20px !important;
    text-align: unset !important;
  }

  .bg-image {
    background-size: 100%;
    background-position: right -285px top -265px;
  }

  .business-container img {
    width: 50vw;
    height: 100%;
  }

  .row-hidden-a {
    display: contents;
  }

  .row-hidden-button {
    display: none;
  }

  .home-container {
    padding-top: 0px !important;
  }

  .footer-padding {
    padding: 0px 30px;
  }

  .hide-cookie-mobile {
    display: none;
  }

  .hide-cookie-web {
    display: unset;

  }


  .hide-mail-contact-mb {
    display: none;
  }

  .hide-mail-contact-w {
    display: unset;
  }

  .features-container .col-first {
    margin-top: unset !important;
  }

  .business-container .business-container-description-start-en {
    padding: unset;
  }

  .business-container .business-container-description-start-th {
    padding: unset;
  }

  .business-container .business-container-description-end-en {
    padding: unset;
  }

  .business-container .business-container-description-end-th {
    padding: unset;
  }

  .bg-img-home {
    display: unset;
  }

}

@media (min-width:1025px) {

  /* big landscape tablets, laptops, and desktops */
  .main-container {
    padding-top: unset;
  }

  /* .bg-image {
    background-size: 100%;
    background-position: right -285px top -265px;
  } */

  .business-container-left {
    position: relative !important;
    /* left: unset; */
  }

  .business-container-right {
    position: relative !important;
    /* left: unset; */
  }

  /* .col-second {
    padding-left: 10px !important;
  } */

  .fetures-layout {
    display: grid;
    text-align: center;
  }

  nav .navbar-collapse {
    position: unset;
    top: unset;
    background: unset;
    right: unset;
    width: unset;
    height: unset;
    display: unset;
    transition: unset;
  }

  nav .navbar-collapse.show {
    right: unset;
  }

  .navbar-hidden-burger {
    display: none;
    
  }

  .navbar-hidden-row {
    color: #fe6202;
    display: flex;
  }

  .bg-image {
    background-size: 100%;
    background-position: right -285px top -300px;
  }

  .features-container h2 {
    margin-top: 30px !important;
  }
}

@media (min-width:1134px) {

  .fetures-layout {
    display: unset !important;
    text-align: unset !important;
    margin-bottom: 20px;
  }



}

@media (min-width:1200px) {
  .bg-image {
    background-size: 100%;
    background-position: right -285px top -385px;
  }

  .features-container h2 {
    margin-top: 50px !important;
  }
}


@media (min-width:1281px) {

  /* hi-res laptops and desktops */
  .main-container {
    padding-top: 0px;
  }

  .background-image {
    display: none;
  }

  .home-container p {
    font-size: 20px !important;
    text-align: unset !important;
  }

  .business-container p {
    font-size: 20px !important;
    text-align: center !important;
  }

  .bg-image {
    background-size: 1235px;
    background-position: right -240px top -390px;
  }

  .business-container img {
    width: 25vw;
    max-width: 385px;
  }

  .business-container .business-container-content-start {
    align-items: center;
  }

  .business-container .business-container-content-end {
    align-items: center;
  }

}

@media (min-width:1600px) {

  /* hi-res laptops and desktops */

  .background-image {
    display: none;
  }

  .bg-image {
    background-size: 1235px;
    background-position: right -75px top -390px;
  }



}