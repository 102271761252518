
.features-container {
  margin-top: 227px;
}

.features-container h2 {
  color: var(--main-theme-text-color);
  margin-bottom: 15px;
}

.features-container h3 {
  color: var(--main-theme-text-color);
  min-height: 72px;
  line-height: 1.06;
  margin-bottom: 12px;
}

.features-container p {
  color: var(--main-theme-text-color);
}

.features-container img {
  width: 36px;
  height: 36px;
}

.features-container .features-container-header-en {
  color: rgb(0, 0, 72);
  font-family: IBMPlexSansThai-Regular-ABCD;
  font-size: 35px;
}

.features-container .features-container-header-th {
  color: rgb(0, 0, 72);
  font-family: IBMPlexSansThai-SemiBold;
  font-size: 35px;
}

.features-container .col-first {
  float: left;
}

.features-container .col-second {
  float: left;
  padding-left: 20px;
}

.features-container .features-container-topic-en {
  font-family: IBMPlexSansThai-Regular-ABCD;
  font-size: 32px;
}

.features-container .features-container-topic-th {
  font-family: IBMPlexSansThai-Medium;
  font-size: 30px;
}

.features-container .features-container-description-en {
  font-family: SFThonburi-Regular;
  font-size: 16px;
}

.features-container .features-container-description-th {
  font-family: IBMPlexSansThai-Regular;
  font-size: 16px;
}