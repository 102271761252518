.home-container {
  padding-top: 70px;
}

.home-logo {
  width: 262px;
}

.home-container .home-header-topic-en {
  color: rgb(0, 0, 72);
  font-family: Gilroy-Bold;
  font-size: 40px;
  line-height: 1.18;
}

.home-container .home-header-topic-th {
  color: rgb(0, 0, 72);
  font-family: NotoSansThai-Bold;
  font-size: 38px;
  line-height: 1.25;
}

.home-container h1 {
  color: var(--main-theme-text-color);
}

.home-container .home-header-description-en {
  font-family: SFThonburi-Regular;
}

.home-container .home-header-description-th {
  font-family: IBMPlexSansThai-Regular;
}

.home-container p {
  font-size: 20px;
  line-height: 1.5;
  color: var(--main-theme-text-color);
}

.home-container a {
  font-size: 24px;
  color: var(--main-theme-color);
  text-decoration: none;
  cursor: pointer;
}

.home-container .get-started-en {
  font-weight: bold;
  color: rgb(0, 0, 72);
  font-family: SFThonburi-Regular;
}

.home-container .get-started-th {
  font-weight: bold;
  color: rgb(0, 0, 72);
  font-family: IBMPlexSansThai-Regular;
}

.home-container a:hover {
  color: #e90002;
}

.home-container .Rectangle-17213 {
  width: 142.7px;
  height: 163.5px;
  padding: 13.7px 8.4px 18.9px 15.4px;
  border-radius: 10px;
  box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #ececec;
  background-color: #fff;
  margin-right: 12px;
  float: left;
}

.home-container .Rectangle-17213 img {
  width: 27.5px;
  height: 27.5px;
}

.Rectangle-17213>.Rectangle-icon {
  margin-bottom: 21px;
}

.Rectangle-17213>.Rectangle-topic {
  margin-bottom: 12px;
}

.Rectangle-17213>.Rectangle-topic>span {
  color: var(--main-theme-text-color);
  font-size: 15px;
}

.Rectangle-17213>.Rectangle-topic>.Rectangle-topic-en {
  color: rgb(0, 0, 72);
  font-family: SFThonburi-Bold;
}

.Rectangle-17213>.Rectangle-topic>.Rectangle-topic-th {
  color: rgb(0, 0, 72);
  font-family: NotoSansThai-Bold;
}

.Rectangle-17213>.Rectangle-description {
  line-height: 0.75em;
}

.Rectangle-17213>.Rectangle-description>span {
  font-size: 12px;
  color: var(--main-theme-text-color);
}

.Rectangle-17213>.Rectangle-description>.Rectangle-description-en {
  font-family: SFThonburi-Regular;
}

.Rectangle-17213>.Rectangle-description>.Rectangle-description-th {
  font-family: IBMPlexSansThai-Regular;
}

.navbar-toggle-color .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important
}

.navbar-toggle-color-black .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important
}