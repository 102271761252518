
.business-container {
  margin-top: 167px;
}

.business-container h1 {
  color: var(--main-theme-text-color);
  margin-bottom: 26px;
}

.business-container h2 {
  color: var(--main-theme-text-color);
  margin-bottom: 15px;
}

.business-container p {
  color: var(--main-theme-text-color);
  line-height: 1.25;
}

.business-container .business-container-header-en {
  color: rgb(0, 0, 72);
  font-family: IBMPlexSansThai-Regular-ABCD;
  font-size: 35px;
}

.business-container .business-container-header-th {
  color: rgb(0, 0, 72);
  font-family: IBMPlexSansThai-SemiBold;
  font-size: 35px;
}

.business-container .business-container-topic-en {
  color: rgb(0, 0, 72);
  font-family: IBMPlexSansThai-Regular-ABCD;
  font-size: 39px;
}

.business-container .business-container-topic-th {
  color: rgb(0, 0, 72);
  font-family: IBMPlexSansThai-Medium;
  font-size: 34px;
}

.business-container .business-container-description-en {
  font-family: SFThonburi-Regular;
  font-size: 20px;
}

.business-container .business-container-description-th {
  font-family: IBMPlexSansThai-Regular;
  font-size: 20px;
}

.business-container .business-container-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}