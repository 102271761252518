.navbar-customize {
  padding: 16px 0 10px 0;
}

.navbar-customize>.container-xxl>.navbar-collapse>.navbar-nav>a {
  padding-left: 1rem;
  padding-right: 1rem;
}

.navbar-customize-lang-en {
  
  font-family: SFThonburi-Regular;
}

.navbar-customize-lang-th {
  font-family: IBMPlexSansThai-Regular;
}

.navbar-customize-lang-fixed-locale {
  font-family: SFThonburi-Regular !important;
  color: #df0002 !important;
}

.navbar-customize-lang-fixed-locale-none {
  color: #c4c4c4 !important;
}
.navbar-customize-lang-fixed-locale-bar {
  color: #c4c4c4 !important;
}

.navbar-customize-fixed {
  background-color: rgba(255, 255, 255, 0.808);
}

.navbar-customize-fixed>.container-xl>.navbar-collapse>.navbar-nav>a,
.navbar-customize-fixed>.container-xl>.navbar-collapse>.navbar-nav>.navbar-text {
  position: relative;
  font-size: 16px;
  color: white;
}

/*
.aaaa:after {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 25%;
  width: 30px;
  border-bottom: 1px solid var(--main-theme-color);;
}
*/

.navbar-customize-sticky-fixd-open {
  background-color: white;
}

.navbar-customize-sticky {
  background-color: white;
  /* box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05); */
  /* opacity: 75%; */
}

.navbar-customize-sticky>.container-xxl>.navbar-collapse>.navbar-nav>a,
.navbar-customize-sticky>.container-xxl>.navbar-collapse>.navbar-nav>.navbar-text {
  color: #65676b;
}

.navbar-brand-none {
  display: none;
}

.navbar-brand {
  width: 110px;
}

.btn-regis-now {
  width: 117px;
  height: 29px;
  border-radius: 8px;
  padding: 0;
}

.btn-regis-now:hover {
  color: red;
  border-color: red;
  background-color: transparent;
}

.btn-regis-now:active,
.btn-regis-now:focus {
  box-shadow: none !important;
}

.navbar-background-div {
  display: block;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(193, 18, 18, 0.05);
  opacity: 0.75;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0px;
}

.sticky-top {
  z-index: 500;
}

@media (max-width: 720px) {
  .navbar-brand {
    display: block;
    width: 72px;
  }
}

.navbar-lang-align {
  display: flex;
  justify-content: center;
  padding-top: calc(100vh - 400px);
}

.navbar-regigter {
  margin-left: 33%;
  margin-top: 20px;
  text-align: center;
  background-color: white; 
  color: black; 
  border: 1px solid #000000;
  border-radius: 10px;
  width: 130px;
}
.navbar-regigter:hover {
  margin-left: 30%;
  margin-top: 20px;
  border-radius: 10px;
  text-align: center;
  background-color: black; 
  color: white; 
  border: 1px solid #000000;
  width: 130px;
}


