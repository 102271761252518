.policy-modal-mobile .modal-dialog {
  margin: unset;
}

.policy-modal-mobile h1 {
  font-size: 22px;
  color: var(--main-theme-text-color);
  text-align: center;
  margin-bottom: 30px;
}

.policy-modal-mobile .contact-policy-header-en {
  color: rgb(0, 0, 72);
  font-family: Gilroy-Bold;
}

.policy-modal-mobile .contact-policy-header-th {
  color: rgb(0, 0, 72);
  font-family: IBMPlexSansThai-SemiBold;
}

.policy-modal-mobile .contact-policy-content {
  /* margin: 30px 0px 0px 0px; */
  max-height: calc(100vh - 10px);
  overflow-y: auto;
  padding-right: 10px;
}

.policy-modal-mobile .policy-accept {
  width: 100%;
  height: 42px;
  padding-left: 28.5px;
  padding-right: 28.5px;
  border-radius: 8px;
  box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.06);
  background-color: rgb(0, 0, 72);
  color: #ffffff;
  border-color: unset;
}

.policy-modal-mobile .policy-accept:hover {
  background-color: rgb(0, 0, 72);
}

.policy-modal-mobile .policy-accept:disabled {
  height: 42px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.06);
  background-color: #f0f2f5;
  border-color: #f0f2f5;
  color: #c4c4c4;
}

.policy-modal-mobile .btn-primary:active:focus {
  box-shadow: 0 0 0 0.1rem rgb(254 133 18 / 25%);
}

.policy-modal-mobile .policy-accept-en {
  font-size: 16px;
  font-family: SFThonburi-Bold;
}

.policy-modal-mobile .policy-accept-th {
  font-size: 16px;
  font-family: IBMPlexSansThai-SemiBold;
}

.policy-modal-mobile .modal-content {
  border-radius: unset;
  box-shadow: unset;
  border: unset;
  background-color: #fff;
  width: 100vw;
  max-height: 100vh;
}

.policy-modal-mobile .modal-header {
  border: 0;
  padding: 28px 28px 0 0;
}

.policy-modal-mobile .modal-body {
  padding: 0px 25px 25px 25px;
}

.policy-modal-mobile .btn-close:focus {
  box-shadow: 0 0 0 0.1rem rgb(254 133 18 / 25%);
}

.policy-modal-mobile .contact-policy-content-1 {
  color: var(--main-theme-text-color);
}

.policy-modal-mobile .contact-policy-content-1-en {
  font-size: 14px;
  font-family: SFThonburi-Regular;
}

.policy-modal-mobile .contact-policy-content-1-th {
  font-size: 16px;
  font-family: IBMPlexSansThai-Regular;
}

.policy-modal-mobile .contact-policy-content-2 {
  color: rgb(0, 0, 72);
}

.policy-modal-mobile .contact-policy-content-2-en {
  font-family: IBMPlexSansThai-Regular-ABCD;
  font-size: 16px;
}

.policy-modal-mobile .contact-policy-content-2-th {
  font-family: IBMPlexSansThai-Medium;
  font-size: 16px;
}

.policy-modal-mobile .contact-policy-content-3 {
  color: var(--main-theme-text-color);
}

.policy-modal-mobile .contact-policy-content-3-en {
  font-family: SFThonburi-Regular;
  font-size: 14px;
}

.policy-modal-mobile .contact-policy-content-3-th {
  font-family: IBMPlexSansThai-Regular;
  font-size: 16px;
}

.policy-modal-mobile .bullets {
  margin-left: 11px;
  margin-right: 11px;
  font-size: 6px;
  color: #e10000;
}



@media (min-width: 1200px) {
  .policy-modal-mobile .modal-xl {
    max-width: 850px;
  }
}