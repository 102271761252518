.footer-container {
  margin-top: 200px;
  padding-top: 42px;
  background-color: var(--main-theme-bg-footer-color);
}

.footer-container .footer-container-top p {
  color: var(--main-theme-topic-footer-color);
  font-size: 16px;
}

.footer-container .footer-container-top .footer-topic-en {
  font-family: SFThonburi-Regular;
}

.footer-container .footer-container-top .footer-topic-th {
  font-family: IBMPlexSansThai-Regular;
}

.footer-container .footer-container-description a {
  color: var(--main-theme-text-color);
  font-size: 16px;
  cursor: pointer;
}

.footer-container .footer-container-description-en {
  font-family: SFThonburi-Regular;
}

.footer-container .footer-container-description-th {
  font-family: IBMPlexSansThai-Regular;
}

.footer-container .footer-container-description a:hover {
  color: #e10000;
}

.footer-container .footer-container-description .contact-icon {
  width: 25px;
  height: 25px;
  margin: 0px 10px;
  cursor: pointer;
}

.footer-container .footer-container-description .app-store {
  width: 125px;
  margin-right: 5px;
}

.footer-container .footer-cp {
  font-family: SFThonburi-Regular;
  color: var(--main-theme-text-color);
  margin-bottom: 16px;
}

.footer-container-resp {
  display: none;
}

.ft-resp-v {
  display: none;
}